import { template as template_eba970e1634c42209cfc911c1b789850 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_eba970e1634c42209cfc911c1b789850(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
