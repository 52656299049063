import { template as template_4c2b9de89c07413da37fdc2a9c2da0e2 } from "@ember/template-compiler";
const SidebarSectionMessage = template_4c2b9de89c07413da37fdc2a9c2da0e2(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
